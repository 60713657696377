









































import { Component, Vue } from 'vue-property-decorator'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

@Component({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
  private open_1 = false
  private open_2 = false
  private open_3 = false
  private open_4 = false
  private open_5 = false
  private open_6 = false

  coaches: any = [
    {
      name: 'Barbara Lainerberger',
      titel: 'Mag.',
      lead: 'Fehlt es am Wind, dann greife zum Ruder',
      desc:
        'Ich habe Kunstgeschichte und Sozialpädagogik studiert. Von Jahr zu Jahr begeisterte mich der sozialpädagogische Bereich mehr und mehr, sodass ich eine Weiterbildung in Sexualpädagogik absolviert habe. Worauf die Ausbildung zur Supervisorin und Coach folgte. Das Arbeiten mit Menschen begleitet mich schon sehr lange und fasziniert mich nach wie vor. Als Gesundheitsmanagerin habe ich viel Erfahrung sammeln dürfen zu den Themen: Konflikte im Betrieb, psychische Belastungen am Arbeitsplatz, Steigerung der Zufriedenheit der Mitarbeiter/innen sowie deren Motivation.',
      bilder: [
        { file: 'barbara.avif', ext: 'avif' },
        { file: 'barbara.jpg', ext: 'jpg' },
      ],
      url: 'https://www.barbara-lainerberger.com/',
      linkText: '',
    },
    {
      name: 'Dominik Boria',
      titel: '',
      lead: 'Veränderung ermöglichen, Zusammenhalt stärken',
      desc:
        'Die eigenen Verhaltens- und Arbeitsweisen zu betrachten ist im Alltag selten möglich. In der Supervision schaffe ich einen sicheren Raum, den Blick dafür zu öffnen und als Team oder als Führungsperson gestärkt daraus hervorzugehen. Bei der Supervision steht der Mensch im Zentrum. Ich schaffe eine angenehme Atmosphäre, in der wir gemeinsam an Ihrer individuellen Lösung arbeiten. Nach der zu Beginn definierten Zeit werden die Lösungserfolge evaluiert und mögliche Zukunftsszenarios – mit oder ohne weitere Supervision – gemeinsam besprochen.',
      bilder: [
        { file: 'dominik.avif', ext: 'avif' },
        { file: 'dominik.jpg', ext: 'jpg' },
      ],
      url: 'https://www.boria-supervision.at/',
      linkText: '',
    },
    {
      name: 'Florian Stradner',
      titel: 'Mag. (FH)',
      lead: 'Geh deinen Weg, denn er ist gut',
      desc:
        'Seit über 20 Jahren sammle ich Erfahrung in der Führung und dem Coaching von Menschen und beschäftige mich mit Veränderungsprozessen in einer digitalen Welt. Nach dem Studium der Betriebswirtschaftslehre habe ich mich in der Unternehmensberatung auf das Projektmanagement fokussiert. Dabei faszinieren mich die Themen Zusammenarbeit von Menschen in Teams und das Leadership. Angespornt durch den Erfolg, begann ich die Ausbildung Supervision & Coaching beim ÖAGG (Österreichische Arbeitskreis für Gruppentherapie und Gruppendynamik) in Wien, um noch mehr zu lernen und Menschen in die Leistung und Zufriedenheit zu begleiten.',
      bilder: [
        { file: 'florian.avif', ext: 'avif' },
        { file: 'florian.jpg', ext: 'jpg' },
      ],
      url: 'https://stradner.coach',
      linkText: '',
    },
    {
      name: 'Julia Seyss-Inquart',
      titel: 'Mag. Dr.',
      lead: 'Empathie und situative Kompetenzen',
      desc:
        'Ich stelle den Prozess und Sie als Person mit Ihren Anliegen in den Mittelpunkt und arbeite deshalb methodenintegrativ. Von meinem Grundberuf als Bildungswissenschafterin bringe ich eine fragende und analytische Haltung mit. Durch Tätigkeiten in unterschiedlichsten Arbeitskontexten bin ich für die Anforderungen und Zumutungen aber auch für die Freuden des beruflichen Alltags in Organisationen sensibel. Organisationsberatung, Supervision und Coaching brauchen Empathie und situative Kompetenzen, zusätzlich brauchen sie jedoch auch fundierte Theorie und Forschung. Von meinem Engagement als Tanztrainerin weiß ich, dass Freiheit und Improvisation erst durch Kommunikation und durch die gemeinsame Einigung auf Regeln entstehen (und es hat mir eine Leidenschaft für Jazz beschert). Berufliche Veränderungen sind eine Kopfsache, die nie ohne Gefühle auskommt!',
      bilder: [
        { file: 'julia.avif', ext: 'avif' },
        { file: 'julia.jpg', ext: 'jpg' },
      ],
      url: 'https://www.juliaseyss.com/',
      linkText: '',
    },
  ]
}
