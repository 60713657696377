






















































import { CardItem } from '@/interfaces'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Card extends Vue {
  @Prop({ required: true })
  private readonly items!: CardItem[]
  @Prop({ default: false, type: Boolean })
  private readonly isLeft!: boolean

  open: boolean[] = new Array<boolean>(this.items.length).fill(false)

  toggle(index: number) {
    this.open = this.open.map((item, i) => {
      return i === index ? !item : item
    })
  }
}
