










import { Component, Vue } from 'vue-property-decorator'
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

@Component({
  components: { MyFooter, MyHeader },
})
export default class Events extends Vue {}
