import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import OnePager from '@/views/OnePager.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'OnePager',
    component: OnePager,
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () =>
      import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue'),
  },
  // {
  //   path: '/kontakt',
  //   name: 'Kontakt',
  //   component: () =>
  //     import(/* webpackChunkName: "kontakt" */ '../views/Kontakt.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: { x: 0, y: 80 },
      }
    }
    return { x: 0, y: 0 }
  },
})

export default router
