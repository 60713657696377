












import { Component, Vue } from 'vue-property-decorator'
import Home from '@/views/Home.vue'
import Services from '@/views/Services.vue'
import Beratung from '@/views/Beratung.vue'
import Events from '@/views/Events.vue'
import Gesundheit from '@/views/Gesundheit.vue'

@Component({
  components: {
    Home,
    Services,
    Beratung,
    Events,
    Gesundheit,
  },
})
export default class OnePager extends Vue {}
