var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center mt-64 md:mt-144 mb-32"},[_c('div',{staticClass:"flex flex-col w-full sm:w-3/4 px-16 md:px-128"},[_c('h2',{staticClass:"text-center w-full"},[_vm._v("Unsere Services")]),_c('div',{staticClass:"mt-8 mb-16 text-center"},[_vm._v(" Wir entwickeln und setzen maßgeschneiderte Konzepte um, die ihren Bedürfnissen entsprechen: ")]),_c('div',{staticClass:"flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-24 space-y-24 md:space-y-0 mt-24 mb-128 h-full"},_vm._l((_vm.services),function(ref){
var url = ref.url;
var alt = ref.alt;
var text = ref.text;
var link = ref.link;
return _c('router-link',{key:url,staticClass:"flex-none flex flex-col justify-center items-center w-256 h-256 bg-gray filter drop-shadow-lg text-primary text-base tracking-wide font-700 cursor-pointer",attrs:{"to":("/#" + link)}},[_vm._v(" "+_vm._s(text)+" "),_c('img',{staticClass:"w-200",attrs:{"src":("/img/" + url),"alt":alt}})])}),1),_c('div',{attrs:{"id":"About"}},[_vm._m(0),_c('div',{staticClass:"flex items-center justify-center flex-col md:flex-row space-x-4 md:h-288 -mt-20"},[_c('h2',{staticClass:"flex md:hidden -mt-24 mb-24"},[_vm._v("Das Team:")]),_vm._l((_vm.coaches),function(coach){return _c('a',{key:coach.name,staticClass:"flex flex-col flex-shrink-0",attrs:{"href":coach.url}},[_c('picture',{staticClass:"flex-grow flex-shrink-0 mb-4 sm:mb-8"},[_vm._l((coach.bilder),function(ref){
var file = ref.file;
var ext = ref.ext;
return _c('source',{key:file,attrs:{"srcSet":("/img/" + file),"type":("image/" + ext),"width":"160","height":"240"}})}),_c('img',{staticClass:"w-160 h-240",attrs:{"width":"160","height":"240","decoding":"async","loading":"lazy","src":("/img/" + (coach.bilder[coach.bilder.length - 1].file)),"alt":coach.name}})],2),_c('div',{staticClass:"flex-none text-primary text-2xs font-700 uppercase mb-8"},[_vm._v(" "+_vm._s(coach.name)+" ")])])})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden md:flex flex-col items-center z-10 text-8xl whitespace-nowrap"},[_c('div',{staticClass:"text-background"},[_c('span',{staticClass:"font-700"},[_vm._v("Konzept")]),_vm._v(" Kollektiv ")]),_c('div',{staticClass:"text-primary -mt-56"},[_c('span',{staticClass:"font-700"},[_vm._v(" Konzept")]),_vm._v(" Kollektiv ")]),_c('div',{staticClass:"text-secondary -mt-56"},[_c('span',{staticClass:"font-700"},[_vm._v("Konzept")]),_vm._v(" Kollektiv ")])])}]

export { render, staticRenderFns }