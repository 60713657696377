









































import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class MyFooter extends Vue {
  links = [
    {
      icon: 'IconMobil',
      link: '+43 699 116 09 114',
    },
    {
      icon: 'IconMail',
      link: 'info@konzept-kollektiv.at',
    },
  ]
}
