


















































































import { Component, Vue } from 'vue-property-decorator'
import Overview from '@/components/Overview.vue'
import Card from '@/components/Card.vue'
import { CardItem } from '@/interfaces'
import store from '@/store'

@Component({
  components: {
    Overview,
    Card,
  },
})
export default class Services extends Vue {
  items: CardItem[] = [
    {
      title: 'Partizipative Teamklausuren',
      text:
        'In Teamklausuren geht es um die konzentrierte Arbeit an spezifischen Themen. Bei unserer Konzeptentwicklung und Moderation legen wir besonderen Wert darauf, dass alle Mitarbeitenden ihr Know-How einbringen damit neue Ideen entstehen können.',
    },
  ]

  services = [
    {
      url: 'Beratung.png',
      alt: 'Organisationsberatung und -entwicklung',
      text: 'Organisationsberatung',
      link: 'organisations-beratung-entwicklung',
    },
    {
      url: 'Gesundheit.png',
      alt: 'Betriebliche Gesundheitsförderung',
      text: 'Gesundheitsförderung',
      link: 'Gesundheit',
    },
    {
      url: 'Events.png',
      alt: 'Events und Veranstaltungen',
      text: 'Veranstaltungsdesign',
      link: 'veranstaltungen-moderation-events',
    },
    {
      url: 'Fortbildung.png',
      alt:
        'Fortbildungen für Organisationsberatung als Führungskraft oder Mitarbeiter',
      text: 'Fortbildung',
      link: 'Gesundheit',
    },
  ]

  get coaches() {
    return store.state.coaches
  }
}
