import Vue from 'vue'
// Autoload Icons:
// https://github.com/chrisvfritz/vue-enterprise-boilerplate/blob/master/src/components/_globals.js
// https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components

// https://webpack.js.org/guides/dependency-management/#require-context

/// SVG Icons
const requireComponent = require.context(
  // Look for files in the current directory
  '/public/img/icons',
  // Do look in subdirectories
  true,
  // Only include "Icon" prefixed .vue files
  /[\w-]+\.svg$/
)

try {
  // For each matching file name...
  requireComponent.keys().forEach((relPathAndFileName) => {
    // console.log('relPathAndFileName:', relPathAndFileName)
    // Get the component config
    const componentConfig = requireComponent(relPathAndFileName)
    if (relPathAndFileName) {
      // @ts-expect-error Kann kein ! für nicht undefined verwenden?!
      const componentName = relPathAndFileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')

      // console.log('componentName:', componentName)
      // Globally register the component
      Vue.component(componentName, componentConfig.default || componentConfig)
    }
  })
} catch (error) {
  console.error('error: autoload.ts: SVGs', error)
}

// /// Base Components
// const requireComponent_Base = require.context(
//   // Look for files in the current directory
//   '/src/components/base/',
//   // Do look in subdirectories
//   true,
//   // Only include "Icon" prefixed .vue files
//   /Icon[\w-]+\.vue$/
// )

// try {
//   // For each matching file name...
//   requireComponent_Base.keys().forEach((relPathAndFileName) => {
//     // Get the component config
//     const componentConfig = requireComponent_Base(relPathAndFileName)
//     if (relPathAndFileName) {
//       // @ts-expect-error Kann kein ! für nicht undefined verwenden?!
//       const componentName = relPathAndFileName
//         .split('/')
//         .pop()
//         .replace(/\.\w+$/, '')

//       // Globally register the component
//       Vue.component(componentName, componentConfig.default || componentConfig)
//     }
//   })
// } catch (error) {
//   console.error('error:', error)
// }
