import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    coaches: [
      {
        name: 'Barbara Lainerberger',
        titel: 'Mag.',
        lead: 'Fehlt es am Wind, dann greife zum Ruder',
        desc:
          'Ich habe Kunstgeschichte und Sozialpädagogik studiert. Von Jahr zu Jahr begeisterte mich der sozialpädagogische Bereich mehr und mehr, sodass ich eine Weiterbildung in Sexualpädagogik absolviert habe. Worauf die Ausbildung zur Supervisorin und Coach folgte. Das Arbeiten mit Menschen begleitet mich schon sehr lange und fasziniert mich nach wie vor. Als Gesundheitsmanagerin habe ich viel Erfahrung sammeln dürfen zu den Themen: Konflikte im Betrieb, psychische Belastungen am Arbeitsplatz, Steigerung der Zufriedenheit der Mitarbeiter/innen sowie deren Motivation.',
        bilder: [
          { file: 'barbara.avif', ext: 'avif' },
          { file: 'Barbara.webp', ext: 'webp' },
          { file: 'barbara.jpg', ext: 'jpg' },
        ],
        url: 'https://www.barbara-lainerberger.com/',
        linkText: '',
      },
      {
        name: 'Dominik Boria',
        titel: '',
        lead: 'Veränderung ermöglichen, Zusammenhalt stärken',
        desc:
          'Die eigenen Verhaltens- und Arbeitsweisen zu betrachten ist im Alltag selten möglich. In der Supervision schaffe ich einen sicheren Raum, den Blick dafür zu öffnen und als Team oder als Führungsperson gestärkt daraus hervorzugehen. Bei der Supervision steht der Mensch im Zentrum. Ich schaffe eine angenehme Atmosphäre, in der wir gemeinsam an Ihrer individuellen Lösung arbeiten. Nach der zu Beginn definierten Zeit werden die Lösungserfolge evaluiert und mögliche Zukunftsszenarios – mit oder ohne weitere Supervision – gemeinsam besprochen.',
        bilder: [
          { file: 'dominik.avif', ext: 'avif' },
          { file: 'dominik.jpg', ext: 'jpg' },
        ],
        url: 'https://www.boria-supervision.at/#ueber-mich',
        linkText: '',
      },
      {
        name: 'Doris Battlogg',
        titel: '',
        lead: 'Veränderung ermöglichen, Zusammenhalt stärken',
        desc:
          'Wenn Bewegung in festgefahrene Situationen oder Strukturen kommt, dann mache ich meinen Job als Beraterin besonders gerne. Deshalb begleitet mich auch die Freude am wohlwollenden Irritieren schon lange und ist für mich ein wesentlicher Bestandteil der Organisationsberatung. Ich bin ausgebildete Sozialarbeiterin, Erlebnispädagogin und freiberufliche Supervision und arbeite inzwischen im Gesundheitsmanagement. Arbeitsgesundheit, Teambuilding und Burn-Out-Prävention sind die Themen, die mir aktuell am wichtigsten sind.',
        bilder: [
          { file: 'doris.avif', ext: 'avif' },
          { file: 'doris.webp', ext: 'webp' },
          { file: 'doris.jpg', ext: 'jpg' },
        ],
        url: 'https://www.doris-battlogg.at/uber-mich/',
        linkText: '',
      },
      {
        name: 'Florian Stradner',
        titel: 'Mag. (FH)',
        lead: 'Geh deinen Weg, denn er ist gut',
        desc:
          'Seit über 20 Jahren sammle ich Erfahrung in der Führung und dem Coaching von Menschen. Nach dem Studium der Betriebswirtschaftslehre habe ich mich auf das Projektmanagement in der Unternehmensberatung fokussiert. Die Zusammenarbeit in Teams fasziniert mich schon lange. Deshalb habe ich mich als Coach auf Leadership und Veränderungsprozesse in einer digitalen Welt spezialisiert. In der Organisationsberatung liegt es mir besonders am Herzen, Menschen in die Leistung und in die Zufriedenheit zu begleiten.',
        bilder: [
          { file: 'florian.avif', ext: 'avif' },
          { file: 'Florian.webp', ext: 'webp' },
          { file: 'florian.jpg', ext: 'jpg' },
        ],
        url: 'https://stradner.coach/ueber-mich',
        linkText: '',
      },
      {
        name: 'Julia Seyss-Inquart',
        titel: 'Mag. Dr.',
        lead: 'Empathie und situative Kompetenzen',
        desc:
          'Berufliche Veränderungen sind für mich eine Kopfsache, die nie ohne Gefühle auskommt. Meine analytische Haltung kommt sicherlich aus meine Tätigkeit als Bildungswissenschafterin, die mich schon an verschiedene Universitäten und Hochschule gebracht hat. Als Tanz- und Pilatestrainer, als Wissenschaftscoach und als Supervisorin habe ich auch gelernt, dass Empathie und situative Kompetenzen genauso wichtig sind. Diese beiden Seiten spielen für mich auch in der Organisationsberatung und in der Konzeption von Veranstaltungen die Hauptrollen.',
        bilder: [
          { file: 'julia.avif', ext: 'avif' },
          { file: 'Julia.webp', ext: 'webp' },
          { file: 'julia.jpg', ext: 'jpg' },
        ],
        url: 'https://www.juliaseyss.com/ueber-mich',
        linkText: '',
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
})
