

















































import { Component, Vue } from 'vue-property-decorator'
import Overview from '@/components/Overview.vue'
import Card from '@/components/Card.vue'
import { CardItem } from '@/interfaces'

@Component({
  components: {
    Overview,
    Card,
  },
})
export default class Events extends Vue {
  items: CardItem[] = [
    {
      title: 'Partizipative Teamklausuren',
      text:
        'In Teamklausuren geht es um die konzentrierte Arbeit an spezifischen Themen. Bei unserer Konzeptentwicklung und Moderation legen wir besonderen Wert darauf, dass alle Mitarbeitenden ihr Know-How einbringen damit neue Ideen entstehen können.',
    },
    {
      title: 'Produktive und lebendige Meetings',
      text:
        'Meetings müssen vieles vereinen: Informationsweitergabe, Entscheidungsfindung, Ideenentwicklung, Austauschbedarf, Aufgabenverteilung etc... Allzu oft laufen Jour-Fixe und Teammeetings nicht optimal: Sie fühlen sich nicht rund an und sind zäh. Wir entwickeln mit Ihnen Konzepte für Ihre regelmäßigen Meetings, bei denen Sie fokussiert zu Ergebnissen kommen und zugleich dynamisch arbeiten können.',
    },
    {
      title: '(Interaktive) Tagungen und Konferenzen',
      text:
        'Fachlicher Austausch und interessante Inputs sind das Ziel von Tagungen und Konferenzen. Doch wie wäre es, wenn Ihre Tagungen und Konferenzen nicht nur interessant sondern auch interaktiv wären? Wenn es nicht nur Input sondern auch Austausch gäbe? Wir konzipieren und setzen Tagungen um, die in Erinnerung bleiben.',
    },
    {
      title: 'Rund ums Projekt',
      text:
        'Projekte stehen und fallen mit der Partizipation der Mitarbeitenden. Gelingt es die Bedeutung des Projekts zu kommunizieren sowie Engagement und Begeisterung zu wecken, ist dies bereits der halbe Projekterfolg. Wir nehmen uns die Zeit, Ihr Projekt kennenzulernen um passgenaue Kick-offs und Abschlussevents für Sie zu entwickeln und (nach Bedarf auch) umzusetzen.',
    },
    {
      title: 'Teamentwicklung',
      text:
        'Erfolgreiche Teams nutzen in ihrer Zusammenarbeit Synergieeffekte, um den Arbeitsauftrag bestmöglich zu erfüllen. Dazu braucht es Vertrauen, die Möglichkeit Konflikte zu bearbeiten, die Offenheit unkonventionelle Lösungen zu integrieren und Strategien für die Zusammenarbeit mit unterschiedlichen Personen. Wir haben in der Teamentwicklung das Team, die einzelnen Personen sowie die gemeinsame Aufgabe im Fokus und schaffen so einen offenen und sicheren Raum für Wachstum, Vertrauen und eine produktive Zusammenarbeit.',
    },
    {
      title: 'Firmenevents und Kommunikation',
      text:
        'Wenn es an der Zeit ist die Erfolge des Unternehmens, die Leistungen der Teams und die Entwicklung von einzelnen Mitarbeitenden zu feiern, dann braucht es Events, die begeistern sowie einen Mehrwert für Kommunikation und Teamentwicklung bieten. Wir entwerfen Firmenevents, die Ihren Erfolg sichtbar machen und Wertschätzung für alle Stakeholder ausdrücken.',
    },
  ]
}
