





























import { Component, Vue } from 'vue-property-decorator'
import Overview from '@/components/Overview.vue'
import Card from '@/components/Card.vue'
import { CardItem } from '@/interfaces'

@Component({
  components: {
    Overview,
    Card,
  },
})
export default class Beratung extends Vue {
  items: CardItem[] = [
    {
      title: 'Unternehmenskultur verändern',
      text:
        'Die Unternehmenskultur ist die Basis für den gemeinsamen Erfolg. Sie             zeigt sich im Umgang miteinander, in der Motivation und Loyalität            der Mitarbeiter:innen, in der Feedback- und Fehlerkultur sowie in            geteilten Werten und Zielen.<br />            Sie möchten für Ihr Unternehmen ein Miteinander, das geprägt ist von            Innovation und Verantwortung? Sie können mit unserer Erfahrung die            für Sie passende Unternehmenskultur entwickeln.',
    },
    {
      title: 'Führungskompetenzen ausbauen',
      text:
        'Führungskompetenzen sind der Schlüssel für den erfolgreichen Umgang            miteinander. Führungsverhalten trainieren, Kommunikation bewusst            gestalten, Zeitmanagement lernen, mit Stress und Belastungen besser            umgehen, Mitarbeiter:innengespräche führen und eine Feedbackkultur            etablieren - all das ist Teil von Führungskompetenzen und kann            gelernt werden. <br />            Ihre Mitarbeiter:innen sollen motiviert sein und ihre Kompetenzen            nutzen können? Wir bieten Einzelcoachings und Trainings zum Ausbau            von Führungskompetenzen an und bauen gerne mit Ihnen eine Leadership            Academy auf.',
    },
    {
      title: 'Zusammenarbeit verbessern',
      text:
        'Wenn Teams wirklich gut ins Arbeiten kommen, maximiert das ihre            Performance. Dabei spielen viele Faktoren eine Rolle: der Umgang            miteinander, die Kommunikation im Team, wie mit Konflikten            umgegangen wird, Onboardingprozesse, Know-how Management und            Wissenstransfer, transparente und sinnvolle Aufgabenverteilung, der            Austausch zwischen Expert:innen oder die Gestaltung eines            motivierenden Umfeldes. <br />            Die Arbeit mit Teams ist deshalb für uns ein zentraler Ansatzpunkt            in der Organisationsentwicklung.',
    },
    {
      title: 'Abläufe und Prozesse optimieren',
      text:
        'Abläufe und Prozesse in Unternehmen sind vielschichtig und benötigen            einen multiperspektivischen Ansatz um die Herausforderungen in ihrer            vollen Tragweite zu erfassen und zu verändern. Wir bieten Beratung            zu folgenden Themen an:            <ul class="list-disc ml-24">              <li class="">                <strong>Prozessorganisation:</strong> Zielabstimmung, Planung,                Feedback, Funktionen und Rollen definieren,                Kund:innenorientierung              </li>              <li class="">                <strong>Entscheidungsprozesse:</strong> Entscheidungsfindung,                Aufbereitung und Umsetzung von Entscheidungen, transparente                Entscheidungen              </li>              <li class="">                <strong>Schnittstellenmanagement:</strong> Zusammenarbeit                zwischen Teams und Abteilungen, Abteilungssilos verändern              </li>              <li class="">                <strong>Kommunikation:</strong> Informationsweitergabe,                informelle Prozesse, Kommunikation innerhalb von Teams und                Projekten und Cross Teams, Email-Kommunikation              </li>              <li class="">                <strong>Aktuelle Managementkonzepte:</strong> Agile                Organization, Lean Management, Lean Prozesse              </li>            </ul>            Wenn es bei Abläufen hackt und Prozesse nicht rund laufen, braucht            es oft den Blick von außen. Wir können unbelastet und mit viel            Gespür die Situation optimieren. Wir sehen das Menschliche in            Prozessen und gestalten diese mit Ihnen.',
    },
  ]
}
